import 'regenerator-runtime/runtime';
import 'core-js';
import 'intersection-observer';
import 'fullscreen-polyfill';
import 'focus-visible';
import ResizeObserver from 'resize-observer-polyfill';

window.ResizeObserver = window.ResizeObserver || ResizeObserver;

export default () => {
  if (window.customElements) {
    return Promise.resolve();
  }

  return import(/* webpackChunkName: "wc" */ '@webcomponents/webcomponentsjs/webcomponents-bundle');
}
